import BaseModel from "@/models/BaseModel";
import UserModel from "@/models/UserModel";

export default class TagModel extends BaseModel {
  type = "tag";

  declare readonly name: string;
  declare readonly createdBy: UserModel;

  toString() {
    return this.name;
  }
}
