import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Preferences } from "@capacitor/preferences";

const useHaptics = async () => {
  // check if haptics are enabled

  let { value } = await Preferences.get({ key: "hapticsEnabled" });

  if (!value) {
    // enable by default
    await Preferences.set({ key: "hapticsEnabled", value: "true" });
    value = "true";
  }
  // convert to boolean
  const valueBoolean = value === "true";

  if (valueBoolean) {
    Haptics.impact({ style: ImpactStyle.Light });
  }
};

export { useHaptics };
