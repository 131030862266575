import router from "@/router";
import client from "./client";

import { SecureStorage } from "@aparajita/capacitor-secure-storage";
import { Preferences } from "@capacitor/preferences";

export type loginResponseData = {
  token?: string;
  needsMigration?: string;
};

export type loginResponse = {
  status: number;
  data: loginResponseData;
};

async function checkUsername(username: string) {
  return client.post(`/check_username/`, {
    username,
  });
}

async function login(account: string, password: string): Promise<loginResponse> {
  const loginRes: loginResponse = await client.post("/login", {
    account,
    password,
  });

  const { token } = loginRes.data;
  if (token) await SecureStorage.set("token", token);
  Preferences.remove({ key: "signupEmail" });

  return loginRes;
}

async function logout() {
  await SecureStorage.remove("token");
  router.replace("/login");
}

async function isLoggedIn() {
  const token = await SecureStorage.get("token");
  return !!token;
}

async function signup(email: string, username: string, password: string) {
  await Preferences.set({ key: "signupEmail", value: email });

  return client.post("/signup/", {
    email,
    username,
    password,
  });
}

async function confirmAccount(uid: string, token: string) {
  return await client.post("/verify/", {
    uid,
    token,
  });
}

async function resendEmail(email: string) {
  if (!email) {
    email = (await Preferences.get({ key: "signupEmail" })).value as string;
  }

  return await client.post("/auth/users/resend_activation/", {
    email,
  });
}

async function requestPasswordReset(email: string) {
  return await client.post("/reset_password/", {
    email,
  });
}

async function resetPassword(
  email: string,
  token: string,
  newPassword: string,
  newPasswordConfirm: string,
) {
  const res = await client.post("/verify_reset_password/", {
    email,
    token,
    password: newPassword,
    passwordConfirmation: newPasswordConfirm,
  });

  const { authToken } = res.data;
  if (authToken) await SecureStorage.set("token", authToken);
  Preferences.remove({ key: "signupEmail" });
}

async function requestAccountDeletion() {
  return await client.post("/delete_account/");
}

async function getSelf() {
  return await client.get("/profile/me/");
}

export {
  checkUsername,
  confirmAccount,
  getSelf,
  isLoggedIn,
  login,
  logout,
  requestAccountDeletion,
  requestPasswordReset,
  resendEmail,
  resetPassword,
  signup,
};
