import BaseModel from "./BaseModel";

export default class NotificationDeviceModel extends BaseModel {
  type = "notificationDevice";

  declare token: string;
  declare platform: string;
  declare isActive: boolean;
  declare userId: string;
}
