import BaseModel from "./BaseModel";

export default class TagStatsModel extends BaseModel {
  type = "tag_stats";

  declare tagsSeeded: number;
  declare placeTags: number;
  declare totalVotes: number;
  declare placesCreated: number;
}
