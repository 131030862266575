import APP from "@/main";
import BaseStore from "@/stores/base-store";
import { Store } from "@/stores/stores";

export default class BaseModel {
  declare readonly id: string;
  declare readonly createdAt: Date;
  declare readonly updatedAt: Date;
  declare store: BaseStore;
  type = "base";

  constructor(params: Record<string, unknown>) {
    Object.assign(this, params);
  }

  get isNew(): boolean {
    return !this.id;
  }

  protected get stores(): Store {
    return APP.config.globalProperties.store;
  }

  serialize(removeKeys: string[] = []): string {
    return JSON.stringify(this, function (k, v) {
      if (!["store", "stores", ...removeKeys].includes(k)) return v;
    });
  }

  toString() {
    return this.id;
  }

  async save(): Promise<void> {
    if (!this.store) {
      throw new Error("You must define a store in the model class in order to use this function");
    }

    const savePromise = this.isNew
      ? this.store._createRecord(this)
      : this.store._updateRecord(this);

    const data = await savePromise;
    Object.assign(this, { ...data });
  }

  async delete(): Promise<void> {
    if (!this.store) {
      throw new Error("You must define a store in the model class in order to use this function");
    }

    if (this.isNew) {
      this.store._removeRecord(this);
    } else {
      await this.store._deleteRecord(this);
    }
  }

  // TODO: Implement functionality that registers a decorated property as something that should be pushed to a related store
  // TODO: Automagically register a store so that we don't have to define a constructor in every class that inherits from this
  static create<T extends typeof BaseModel, U>(this: T, params?: U): T | U {
    const newRecord = new this({ ...params }) as InstanceType<T> & U;
    Object.assign(newRecord, params);
    newRecord.store = newRecord.stores.getStore(newRecord.type);

    try {
      newRecord.store._pushRecord(newRecord);
    } catch (e) {
      console.error(`No store found for model of type ${newRecord.type}`);
    }

    return newRecord;
  }
}

// TODO: Implement functionality that registers a decorated property as something that should be pushed to a related store
// function hasMany(modelType: string, x) {
//   console.log(`Has Many ${modelType}`);

//   console.log(getModel(modelType));
// }

// function hasOne(modelType: string) {
//   console.log(`Has One ${modelType}`);

//   console.log(getModel(modelType));
// }

// function getModel(modelType: string) {
//   try {
//     const capitalizedModelType = modelType.charAt(0).toUpperCase() + modelType.slice(1);

//     return Models[`${capitalizedModelType}Model`];
//   } catch (e) {
//     console.log(e);
//   }
// }

// export { hasMany, hasOne };
