<script lang="ts" setup>
  interface Props {
    image: string;
    title: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    image: "logo",
    title: "# marks the spot",
  });
</script>

<template>
  <div class="logo-wrapper">
    <img :src="props.image" />

    <div>{{ props.title }}</div>
  </div>
</template>

<style lang="scss" scoped>
  .logo-wrapper {
    padding: 1rem;
    text-align: center;

    img {
      width: 100px;
      object-fit: contain;
    }
  }
</style>
