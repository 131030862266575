<script setup lang="ts">
  import { explorePage, feedPage, logo, placePage, tagPage } from "@/assets/img/index";

  const emit = defineEmits(["onComplete"]);

  const didPressBack = () => {
    if (stepIdx.value > 0) {
      stepIdx.value -= 1;
    }
  };

  const stepIdx = ref(0);

  const screenshotStyle =
    "height: 500px;width: 90%;object-fit: cover;object-position: top;box-shadow: 0px 20px 12px -15px rgba(64, 166, 159, 0.49);";
  const steps = [
    {
      title: "Vandal",
      image: logo,
      style: "height:220px;",
      subtext: `Welcome to Vandal. Search your city on your terms,
        and curate good vibes for others by voting on tags. Or better yet,
        create a tag that doesn't exist on Vandal, and build up your influence by
        gaining followers for that tag. We've got big plans for influential vandals…`,
    },
    {
      title: "The Feed",
      image: feedPage,
      style: screenshotStyle,
      subtext: `No reviews, no ratings, no rules. If you love
        a spot, tell it with tags. Get as weird as you want.`,
    },
    {
      title: "Explore",
      image: explorePage,
      style: screenshotStyle,

      subtext: `Find oddly specific vibes near you, and tag hidden
        gems. Ever search for “trivia night”?`,
    },
    {
      title: "Place",
      image: placePage,
      style: screenshotStyle,

      subtext: `Get a feel for a spot in seconds. Add your own flavor,
        or upvote tags to curate your feed.`,
    },
    {
      title: "Tags",
      image: tagPage,
      style: screenshotStyle,

      subtext: `Got a craving on the brain? See your whole city
        through tag-tinted shades.`,
    },
    // {
    //   title: "Seeding Award",
    //   image: logo,
    //   subtext: `Build street cred by creating new tags, or
    //     creating a place that’s not on any map.`,
    // },
  ];

  const currentStep = computed(() => steps[stepIdx.value]);

  const handleNext = () => {
    if (stepIdx.value < steps.length - 1) {
      stepIdx.value += 1;
    } else {
      emit("onComplete");
    }
  };
</script>

<template>
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>{{ currentStep.title }}</IonTitle>
      </IonToolbar>
    </IonHeader>

    <IonContent class="gray-bg">
      <div class="inner-content gray-bg">
        <img :src="currentStep.image" alt="vandal-logo" :style="currentStep?.style" />
        <IonText>{{ currentStep.subtext }}</IonText>
      </div>
    </IonContent>

    <IonFooter>
      <IonToolbar class="footer-wrapper">
        <IonButtons slot="start">
          <IonButton v-haptics :class="stepIdx === 0 ? 'disabled' : ''" @click="didPressBack()">
            Back
          </IonButton>
        </IonButtons>

        <div class="index-dots">
          <div v-for="(step, index) in steps" :key="step.title">
            <div class="dot" :class="index === stepIdx ? 'active' : ''"></div>
          </div>
        </div>
        <IonButtons slot="end">
          <IonButton v-haptics :class="stepIdx === 0 ? 'disabled' : ''" @click="handleNext()">
            {{ stepIdx === steps.length - 1 ? "Explore" : "Next" }}
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  </IonPage>
</template>

<style lang="scss" scoped>
  .gray-bg {
    background-color: var(--ion-color-step-100) !important;
  }
  .inner-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 100%;

    img {
      align-self: center;
    }

    ion-text {
      padding: 0rem 1.5rem;
      padding-bottom: 1rem;
      font-size: 1.2rem;
    }
  }

  .footer-wrapper {
    .button-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 20px;

      ion-button {
        &.disabled {
          pointer-events: none;
        }
      }
    }

    .index-dots {
      justify-content: center;
      padding: 20px 0px;
      display: flex;
      gap: 10px;

      .dot {
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        background-color: var(--main-beerus, #e2e2e2);
        &.active {
          background-color: var(--vandal-teal);
        }
      }
    }
  }
</style>
