import PlaceTagModel from "@/models/PlaceTagModel";
import BaseStore from "@/stores/base-store";

class PlaceTagStore extends BaseStore {
  id = "placeTags";
  didInheritAiTags = false;

  async bulkCreate(placeId: string, tagIds: Array<string>): Promise<any> {
    const res = await this.client.post("/place_tags/bulk_create/", {
      place: placeId,
      tag: "",
      tags: tagIds,
    });

    this.didInheritAiTags = res.data.inheritedAITags;

    return res.data.newPlaceTags.map((params: Record<string, unknown>) =>
      PlaceTagModel.create(params),
    );
  }

  async getVotes(placeId: string): Promise<any> {
    const res = await this.client.get("/votes/", { params: { place_id: placeId } });

    return res.data;
  }

  async votePlaceTag(placeTagId: string, vote: number): Promise<any> {
    const res = await this.client.post(`/place_tags/${placeTagId}/votes/`, {
      vote: vote,
    });

    return res.data;
  }
}

export default new PlaceTagStore();
