import app from "@/main";
import * as Models from "@/models/index";
import client from "@/services/api/client";
import { underscore } from "@/utils/not-lodash";

export default class BaseStore {
  declare readonly id: string;
  protected readonly _records: Ref<(typeof this.modelType)[]>;
  protected readonly client = client;
  protected page = 1;
  protected nextPage = 1;

  constructor() {
    this._records = ref([]);
  }

  private get modelType() {
    return this.getModel(this.id.slice(0, -1));
  }

  protected get baseUrl() {
    return underscore(this.id);
  }

  public get records() {
    return this._records.value;
  }

  public set records(value) {
    this._records.value = value;
  }

  protected get stores() {
    return app.config.globalProperties.store;
  }

  protected reset() {
    this._records.value = [];
    this.nextPage = 0;
    this.page = 0;
  }

  public async findRecord(id: string, params = {}) {
    const findRecordRes = this._findRecord(id, params);
    const existingRecord = this.records.find((r: Models.BaseModel) => r.id === id);

    if (existingRecord) {
      return existingRecord;
    } else {
      const newRecord = await findRecordRes;

      return newRecord;
    }
  }

  private async _findRecord(id: string, params = {}) {
    const res = await client.get(`/${this.baseUrl}/${id}/`, { params });

    return this.modelType.create(res.data);
  }

  public async findRecords(params = {}, replaceStore = false) {
    const res = await client.get(`/${this.baseUrl}/`, { params });
    const recordsJSON = res.data.meta ? res.data.data : res.data;

    if (replaceStore) this.reset();

    const newRecords = recordsJSON.map((json: object) => this.modelType.create(json));

    return newRecords;
  }

  public async _createRecord(record: Models.BaseModel) {
    const res = await client.post(`/${this.baseUrl}/`, record.serialize());
    const updatedRecord = Object.assign(record, res.data);

    return updatedRecord;
  }

  public async _updateRecord(record: Models.BaseModel) {
    const res = await client.put(`/${this.baseUrl}/${record.id}/`, record.serialize());
    const newRecord = this._pushRecord(res.data);

    return newRecord;
  }

  public async _deleteRecord(record: Models.BaseModel) {
    await client.delete(`/${this.baseUrl}/${record?.id}/`);

    this._removeRecord(record);
  }

  public _removeRecord(record: Models.BaseModel) {
    this.records = this.records.filter((r) => r.id !== record.id);
  }

  public _pushRecord(record: Models.BaseModel) {
    const findFn = ({ id }: Models.BaseModel) => id === record.id;
    const existingIndex = this.records.findIndex(findFn);

    if (existingIndex >= 0) {
      this.records[existingIndex] = record;
    } else {
      this.records.push(record);
    }
  }

  private getModel(modelType: string) {
    const capitalizedModelType = `${modelType.charAt(0).toUpperCase()}${modelType.substring(1)}`;

    // @ts-expect-error typescript just doesn't like accessing the object returned from export files
    return Models[`${capitalizedModelType}Model`];
  }
}
