import BaseModel from "@/models/BaseModel";
import type TagModel from "@/models/TagModel";

export type Coordinates = {
  coords: Array<number>;
  latitude: number;
  longitude: number;
};

export default class PlaceModel extends BaseModel {
  type = "place";

  declare name: string;
  declare osmId: number;
  declare distanceInMeters: number;
  declare location: Coordinates;
  declare totalTags: number;
  declare createdBy: string;
  declare tags: Array<TagModel>;
  declare topTags: Array<TagModel>;

  toString() {
    return this.name;
  }
}
