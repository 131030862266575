import reactive from "@/decorators/reactive";
import TagModel from "@/models/TagModel";
import BaseStore from "@/stores/base-store";
import Bugsnag from "@bugsnag/js";

class TagStore extends BaseStore {
  id = "tags";

  @reactive([])
  declare searchedTags: TagModel[];

  searchTagsAbortSignals = [] as Array<AbortController>;

  async searchTags(query: string) {
    try {
      const controller = new AbortController();
      const signal = controller.signal;

      this.searchTagsAbortSignals.push(controller);

      const res = await this.client.get(`/tags/search/?q=${query}`, { signal });
      const tagModels = res.data.map((params: Record<string, unknown>) => TagModel.create(params));

      this.searchedTags = tagModels;

      // remove signal after successful request
      const idx = this.searchTagsAbortSignals.indexOf(controller);

      if (idx !== -1) this.searchTagsAbortSignals.splice(idx, 1);
    } catch (e: any) {
      Bugsnag.notify(e);
    }
  }

  cancelAllSearchRequests() {
    this.searchTagsAbortSignals.forEach((abortController) => abortController.abort());
    this.searchTagsAbortSignals = [];
  }
}

export default new TagStore();
