/* this implementation is original ported from https://github.com/logaretm/vue-use-web by Abdelrahman Awad */

import Bugsnag from "@bugsnag/js";
import type { PermissionState } from "@capacitor/core";
import { Geolocation, GeolocationPlugin, Position } from "@capacitor/geolocation";

import { Capacitor } from "@capacitor/core";
import { tryOnScopeDispose } from "@vueuse/shared";
import type { Ref } from "vue";
import { computed, ref, shallowRef } from "vue";

export interface UseGeolocationOptions extends Partial<PositionOptions> {
  immediate?: boolean;
  navigator?: GeolocationPlugin;
}

/**
 * Reactive Geolocation API.
 *
 * @see https://vueuse.org/useGeolocation
 * @param options
 */
export function useGeolocation(options: UseGeolocationOptions = {}) {
  const {
    enableHighAccuracy = false,
    maximumAge = 30000,
    timeout = 27000,
    navigator = Geolocation,
    immediate = false,
  } = options;

  const permission: Ref<PermissionState | null> = ref(null);

  const isGranted = computed(() => {
    return permission.value == "granted";
  });

  const isDenied = computed(() => {
    return permission.value == "denied";
  });

  const locatedAt: Ref<number | null> = ref(null);
  const error = shallowRef<GeolocationPositionError | null>(null);
  const coords: Ref<Position["coords"]> = ref({
    accuracy: 0,
    latitude: Number.POSITIVE_INFINITY,
    longitude: Number.POSITIVE_INFINITY,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });

  async function checkPermission(): Promise<PermissionState | null> {
    try {
      const status = await navigator.checkPermissions();
      permission.value = status.location || status.coarseLocation;
      return permission.value;
    } catch (e: any) {
      Bugsnag.notify(e);
    }
    return null;
  }

  async function requestPermission() {
    // only runs on native
    if (Capacitor.getPlatform() === "web") {
      return;
    }
    try {
      const status = await navigator.requestPermissions();
      permission.value = status.location || status.coarseLocation;
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(e);
      return;
    }
  }

  function updatePosition(position: Position | null, err?: any) {
    if (position) {
      locatedAt.value = position.timestamp;
      coords.value = position.coords;
    } else if (err) {
      error.value = err;
    }
  }

  let watcher: string;

  async function fetchCurrentLocation() {
    if (!permission.value) {
      await checkPermission();
    }

    if (permission.value == "prompt" || permission.value == "prompt-with-rationale") {
      await requestPermission();
    }

    if (isGranted.value || Capacitor.getPlatform() === "web") {
      const res = await navigator.getCurrentPosition({
        enableHighAccuracy,
        maximumAge,
        timeout,
      });

      updatePosition(res);
    }
  }

  // Do not use

  async function resume() {
    if (!permission.value) {
      await checkPermission();
    }

    if (isGranted.value || Capacitor.getPlatform() === "web") {
      watcher = await navigator.watchPosition(
        {
          enableHighAccuracy,
          maximumAge,
          timeout,
        },
        updatePosition,
      );
    }
  }

  if (immediate) resume();

  function pause() {
    if (watcher) navigator.clearWatch({ id: watcher });
  }

  tryOnScopeDispose(() => {
    pause();
  });

  return {
    permission,
    isGranted,
    isDenied,
    coords,
    locatedAt,
    error,
    resume,
    pause,
    checkPermission,
    requestPermission,
    fetchCurrentLocation,
  };
}

export type UseGeolocationReturn = ReturnType<typeof useGeolocation>;

// Singleton pattern
// we only ever need 1 location service running &
// we want it running as soon as possible
const instance = useGeolocation();

export default instance;
