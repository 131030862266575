<script setup lang="ts">
  import { Preferences } from "@capacitor/preferences";
  import { IonApp, IonRouterOutlet } from "@ionic/vue";
  import { onMounted, ref } from "vue";
  import IntroPage from "./pages/IntroPage.vue";

  const showIntro = ref(false);

  const setTheme = async () => {
    //  remove any cached preference
    await Preferences.remove({ key: "VandalUserTheme" });

    const html = document.querySelector("html");
    // force dark mode
    html?.classList?.add?.("dark");
  };

  setTheme();

  const showIntroToVandal = async () => {
    const hasSeenIntro = await Preferences.get({ key: "VandalIntro" });

    if (hasSeenIntro?.value === "true") return;

    showIntro.value = true;
  };

  const handleIntroViewed = () => {
    Preferences.set({ key: "VandalIntro", value: "true" });
    showIntro.value = false;
  };

  onMounted(async () => {
    showIntroToVandal();
  });
</script>

<template>
  <IonApp>
    <IntroPage v-if="showIntro" @on-complete="handleIntroViewed" />
    <IonRouterOutlet v-else />
  </IonApp>
</template>

<style lang="scss">
  ion-refresher {
    z-index: 10;
  }
</style>
