/**
 * Use to define a reactive property on an object. Creates a getter and setter on the target
 * object that maintain a Vue ref under the hood.
 * 
 * Example usage:
    @reactive([])
    declare reactiveArray: Array;
 */
export default function reactive(
  propertyType: Array<unknown> | Record<string, unknown> | string | number,
) {
  return function (target: any, propertyKey: string) {
    const localVal = ref(propertyType);

    const getter = function () {
      return localVal.value;
    };

    const setter = function (value: any) {
      localVal.value = value;
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
    });
  };
}
