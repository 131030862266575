import axios from "axios";
const apiURL = import.meta.env.VITE_API_URL;
import router from "@/router";
import camelize from "camelize-ts";

import { SecureStorage } from "@aparajita/capacitor-secure-storage";

const client = axios.create({
  baseURL: `${apiURL}/v1`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

//  Request Interceptors

client.interceptors.request.use(
  async (req) => {
    const token = await SecureStorage.get("token");

    if (token) {
      req.headers["Authorization"] = `Bearer ${token}`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptors

client.interceptors.response.use(
  (res) => res,
  async (err) => {
    // handle 401 error  {
    // 	"detail": "Invalid token."
    // }
    if (err?.response?.status === 401) {
      await SecureStorage.remove("token");

      router.replace("/login");
    }

    return Promise.reject(err);
  },
);

// camel case middleware for response data
client.interceptors.response.use(
  (res) => {
    res.data = camelize(res.data);
    return res;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default client;
