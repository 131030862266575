import Store from "@/stores/stores";
import type { App, Plugin } from "vue";

export default {
  install: (app: App) => {
    app.config.globalProperties.store = Store; // for use in files outside of app scope

    Store.locations.fetchCurrentLocation();
  },
} as Plugin;
