import { toastController } from "@ionic/vue";

class Toaster {
  async _toastMessage(level: string, message: string, duration = 2000) {
    const toast = await toastController.create({
      color: level,
      message: message,
      duration: duration,
    });

    return toast.present();
  }

  async success(message: string, duration?: number) {
    this._toastMessage("primary", message, duration);
  }

  async warning(message: string, duration?: number) {
    this._toastMessage("warning", message, duration);
  }

  async error(message: string, duration?: number) {
    this._toastMessage("danger", message, duration);
  }
}

export default new Toaster();
