<script setup lang="ts">
  import { eyeOffOutline, eyeOutline } from "ionicons/icons";

  const emit = defineEmits(["onInput"]);

  const shouldShowPassword = ref(false);

  const passwordInputType = computed(() => {
    return shouldShowPassword.value ? "" : "password";
  });

  const icon = computed(() => {
    return shouldShowPassword.value ? eyeOffOutline : eyeOutline;
  });

  const handleInput = (e: any) => {
    emit("onInput", e?.target?.value);
  };

  const toggleShowPassword = () => {
    shouldShowPassword.value = !shouldShowPassword.value;
  };
</script>

<template>
  <div class="input-container">
    <IonInput
      v-autofillpatch
      label-placement="stacked"
      :type="passwordInputType"
      placeholder="password"
      name="password"
      label="password"
      required
      @ion-input="handleInput"
    />

    <IonIcon :icon="icon" @click="toggleShowPassword" />
  </div>
</template>

<style lang="scss" scoped>
  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
  }

  ion-icon {
    height: 30px;
    width: 30px;
    align-self: end;
    margin-bottom: 3px;
  }
</style>
