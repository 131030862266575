const env = import.meta.env.VITE_ENV;
const bugsnagKey = import.meta.env.VITE_BUGSNAG_KEY;

import { instance as AptabaseService } from "./services/internal/aptabaseService";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { Preferences } from "@capacitor/preferences";
import { IonicVue } from "@ionic/vue";
import { createApp } from "vue";
import pkgInfo from "../package.json";
import App from "./App.vue";
import router from "./router";
import dataStorePlugin from "./vandal-data-plugin";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import { Capacitor } from "@capacitor/core";
import "@ionic/vue/css/display.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";

/* Theme variables */
import "@/theme/variables.scss";

import { vHaptics } from "@/directives/vHaptics";
import registerStore from "@/plugins/registerStore";
import { registerListeners } from "@/services/api/pushNotificationService";

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: false,
    // mode: "md",
  })
  .use(router)
  .use(registerStore);

app.directive("haptics", vHaptics);

app.directive("autofillpatch", {
  mounted: (el) => {
    if (Capacitor.getPlatform() === "ios") {
      setTimeout(() => {
        try {
          el.nativeElement.children[0].addEventListener("change", (e) => {
            el.nativeElement.value = (e.target as any).value;
          });
        } catch (e) {
          Bugsnag.notify(e);
        }
      }, 100); // Need some time for the ion-input to create the input element
    }
  },
});

Bugsnag.start({
  apiKey: bugsnagKey,
  appVersion: pkgInfo.version,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production"],
  releaseStage: env,
  metadata: {
    platform: Capacitor.getPlatform(),
  },
  onError: async function (event) {
    const activeUser = app.config.globalProperties.store.users.activeUser.value;
    const id = activeUser.id || "";
    let email = activeUser.email;
    if (!email) {
      const cachedEmail = await Preferences.get({ key: "signupEmail" });
      email = cachedEmail.value || "";
    }

    event.setUser(id, email);
  },
});

const bugsnagVue = Bugsnag.getPlugin("vue");

// @ts-expect-error this works
app.use(bugsnagVue).use(dataStorePlugin);

router.isReady().then(() => {
  app.mount("#app");
  AptabaseService.trackEvent("app_loaded");
  registerListeners(); // register push notif even listeners
});

export default app;
