import toast from "@/services/toastService";

export function showPrivacyError(hasShownPrivacyError: boolean) {
  if (hasShownPrivacyError) return;

  return toast.error(
    `You're not currently sharing your location with us.
    Either enable location services in your browser app settings or
    manually choose a location using the 'Change Location' button.`,
    10000,
  );
}
