import * as Stores from "@/stores/index";

class Store {
  declare readonly places: typeof Stores.PlaceStore;
  declare readonly tags: typeof Stores.TagStore;
  declare readonly placeTags: typeof Stores.PlaceTagStore;
  declare readonly users: typeof Stores.UserStore;
  declare readonly notifications: typeof Stores.NotificationStore;
  declare readonly locations: typeof Stores.LocationStore;
  declare readonly notificationDeviceModels: typeof Stores.NotificationDeviceStore;

  // TODO: Make this dynamic so we don't have to declare every store line by line
  constructor() {
    this.places = Stores.PlaceStore;
    this.tags = Stores.TagStore;
    this.placeTags = Stores.PlaceTagStore;
    this.users = Stores.UserStore;
    this.notifications = Stores.NotificationStore;
    this.locations = Stores.LocationStore;
    this.notificationDeviceModels = Stores.NotificationDeviceStore;
  }

  getStore(recordType: string) {
    // @ts-expect-error typescript just doesn't like accessing the object returned from export files
    return this[`${recordType}s`];
  }
}

export { type Store };

export default new Store();
