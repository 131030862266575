<script setup lang="ts">
  import { logo } from "@/assets/img";
  import UserModel from "@/models/UserModel";
  import router from "@/router";
  import { checkUsername, signup } from "@/services/api/authService";
  import toast from "@/services/toastService";
  import stores from "@/stores/stores";
  import { validateEmail } from "@/validators";

  const userStore = stores.users;

  let email = ref("");
  let password = "";
  let username = ref("");
  let isLoading = ref(false);
  let usernameAvailable = ref(true);

  watch(email, (current: string, _old: string) => {
    // enforce lowercase emails
    email.value = current.toLowerCase();
  });

  async function onUsernameInput() {
    if (username.value) {
      const {
        data: { isAvailable },
      } = await checkUsername(username.value);
      usernameAvailable.value = isAvailable;
    } else {
      usernameAvailable.value = true;
    }
  }

  async function submitSignup() {
    isLoading.value = true;

    if (!validateEmail(email.value)) {
      isLoading.value = false;

      return toast.error("Invalid email. Please enter a valid email.");
    }

    try {
      await signup(email.value, username.value, password);
      // set the email in the store to be used later on
      userStore.activeUser = new UserModel({ email: email.value });
      router.push({
        name: "login",
        params: {
          needsVerification: "true",
        },
      });
    } catch (e: any) {
      let message = "Error creating your account.";

      if (e?.response?.data?.errors) {
        const { rule, field } = e?.response?.data?.errors?.[0];

        if (rule === "unique" && field === "email") {
          message = "Account with email already exists.";
        } else if (rule === "unique" && field === "username") {
          message = "Somebody already took that name. Pick something worse.";
        } else {
          message = e?.response?.data?.errors[0]?.message;
        }
      }
      toast.error(message, 5000);
    } finally {
      isLoading.value = false;
    }
  }

  const updatePassword = (value: string) => {
    password = value;
  };
</script>

<template>
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Vandal</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <ImageWithTitle :image="logo" title="# marks the spot" />
      <form class="ion-padding" @submit.prevent="submitSignup" @keyup.enter="submitSignup">
        <IonList>
          <IonItem>
            <IonInput
              v-model="username"
              v-autofillpatch
              placeholder="username"
              inputmode="text"
              type="text"
              label="username"
              autocapitalize="off"
              autocomplete="username"
              @ion-input="onUsernameInput"
            >
              <div v-if="!usernameAvailable" slot="label" class="username-taken">
                <IonText>username taken</IonText>
              </div>
            </IonInput>
          </IonItem>
          <IonItem>
            <IonInput
              v-model="email"
              v-autofillpatch
              autocomplete="email"
              placeholder="you@example.com"
              inputmode="email"
              type="email"
              name="email"
              label="email"
              autocapitalize="off"
            />
          </IonItem>
          <IonItem>
            <PasswordInput @on-input="updatePassword" />
          </IonItem>
        </IonList>
        <IonButton v-haptics type="submit" expand="block">
          Sign Up

          <IonSpinner v-if="isLoading" name="crescent" class="ion-margin-start"
        /></IonButton>

        <p style="display: flex; align-items: center; justify-content: center">
          Already signed up?
          <IonButton v-haptics router-link="/login" router-direction="none" fill="clear"
            >Login</IonButton
          >
        </p>
      </form>
    </IonContent>
  </IonPage>
</template>

<style lang="scss" scoped>
  .username-taken {
    color: var(--vandal-red);
  }
</style>
