<script setup lang="ts">
  import { logo } from "@/assets/img";
  import router from "@/router";
  import { login } from "@/services/api/authService";
  import toast from "@/services/toastService";
  import { validateEmail } from "@/validators";
  import Bugsnag from "@bugsnag/js";
  import { Preferences } from "@capacitor/preferences";
  import { onIonViewDidLeave } from "@ionic/vue";
  const account = ref("");
  let password = "";
  const isLoading = ref(false);
  const shouldShowInfoModal = ref(false);

  watch(account, (current: string, _old: string) => {
    // enforce lowercase accounts
    account.value = current.toLowerCase();
  });

  const didCloseModal = () => {
    shouldShowInfoModal.value = false;

    // pass email to reset password if the user chose to login with email
    if (validateEmail(account.value)) {
      router.push({ name: "reset_password", query: { email: account.value } });
    } else {
      router.push({ name: "reset_password" });
    }
  };

  const modalText =
    "We've been overhauling our entire infrastructure since your last login, and we're now ready to take you to greener pastures, where fancy new features will bloom in abundance. In making this move, we need your help to reset your password.";
  onIonViewWillEnter(async () => {
    const cachedEmail = (await Preferences.get({ key: "signupEmail" })).value;

    if (cachedEmail) {
      account.value = cachedEmail;
      Preferences.remove({ key: "signupEmail" });
    }
  });

  onIonViewDidLeave(() => {
    shouldShowInfoModal.value = false;
    password = "";
  });

  const submitLogin = async () => {
    isLoading.value = true;

    try {
      const loginResponse = await login(account.value, password);
      if (loginResponse?.data?.needsMigration) {
        //  show modal
        shouldShowInfoModal.value = true;
      } else {
        router.push({ name: "feed" });
        password = "";
      }
    } catch (e: any) {
      Bugsnag.notify(e);
      toast.error("Invalid username & password");
    }

    isLoading.value = false;
  };

  const updatePassword = (value: string) => {
    password = value;
  };
</script>

<template>
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Vandal</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <ImageWithTitle :image="logo" title="# marks the spot" />

      <form id="login" class="ion-padding" novalidate @submit.prevent="submitLogin">
        <IonList>
          <IonItem>
            <IonInput
              v-model="account"
              v-autofillpatch
              label-placement="stacked"
              placeholder="username or email"
              inputmode="email"
              type="text"
              label="login"
              autocomplete="off"
              autocapitalize="off"
              name="username"
              required
            />
          </IonItem>
          <IonItem>
            <PasswordInput @on-input="updatePassword" />
          </IonItem>
        </IonList>
        <IonButton v-haptics type="submit" expand="block">
          Login
          <IonSpinner v-if="isLoading" name="crescent" class="ion-margin-start" />
        </IonButton>
        <p style="display: flex; align-items: center; justify-content: center">
          <IonButton v-haptics router-link="/reset_password" fill="clear">Reset Password</IonButton>
        </p>

        <p style="display: flex; align-items: center; justify-content: center">
          New?
          <IonButton v-haptics router-link="/signup" router-direction="root" fill="clear"
            >Sign up</IonButton
          >
        </p>
      </form>

      <InfoModal :is-open="shouldShowInfoModal" title="Welcome back!" :text="modalText">
        <template #buttons>
          <IonButton
            v-haptics
            color="primary"
            class="ion-align-self-center push-notification-button ion-text-center"
            @click="didCloseModal"
          >
            Let's go!
          </IonButton>
        </template>
      </InfoModal>
    </IonContent>
  </IonPage>
</template>

<style lang="scss" scoped>
  .token {
    color: var(--ion-color-primary);
    font-size: 54px;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
</style>
