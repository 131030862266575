import { init, trackEvent } from "@aptabase/web";

export default class AptabaseService {
  private appKey = import.meta.env.VITE_APTABASE_KEY;

  constructor() {
    if (this.appKey) {
      init(this.appKey);
    }
  }

  public trackEvent(eventName: string, props?: Record<string, string | number | boolean>) {
    if (!this.appKey) return;
    trackEvent(eventName, props);
  }
}

const instance = new AptabaseService();

export { instance };
